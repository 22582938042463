import React, { FC } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartQuestionAnswersResponseType } from "../../types/chartQuestionAnswersInfoType";
import { I18n } from "react-redux-i18n";
import removeMarkdown from "remove-markdown";
import he from "he";
import { barColors } from "./barColors";

type BarChartWithToggleProps = {
  allData: ChartQuestionAnswersResponseType;
  language?: string;
};

const BarChartWithToggleHorizontal: FC<BarChartWithToggleProps> = ({ allData, language = "RU" }) => {
  const getTranslatedText = (translations: any[]) => {
    if (!translations || translations.length === 0) return "";

    const translation = translations.find((t: any) => t.langCode === language);

    return translation?.text || translations[0]?.text || "";
  };

  const processMultipleChoiceData = (answers: any[]) => {
    const totalAnswers = answers.reduce((sum, answer) => sum + answer.answerCount, 0);

    const choiceCounts: { [choiceId: number]: { name: string; total: number; percentage: number } } = {};

    answers.forEach((answer) => {
      if (answer.choices && answer.choices.length > 0) {
        answer.choices.forEach((choice: any) => {
          const name = getTranslatedText(choice.translations);
          if (!choiceCounts[choice.choiceId]) {
            choiceCounts[choice.choiceId] = { name, total: 0, percentage: 0 };
          }
          choiceCounts[choice.choiceId].total += answer.answerCount;
        });
      }
    });

    Object.values(choiceCounts).forEach((choice) => {
      choice.percentage = (choice.total / totalAnswers) * 100;
    });

    return Object.values(choiceCounts);
  };

  const processSingleChoiceData = (answers: any[]) => {
    return answers
      .map((answer) => {
        if (!answer.choices || answer.choices.length === 0) {
          return null;
        }

        const choice = answer.choices[0];
        const name = getTranslatedText(choice.translations);
        const cleanText = he.decode(removeMarkdown(name || answer.answerMessage));

        return {
          name: cleanText,
          total: answer.answerCount,
          percentage: answer.percentage || 0,
        };
      })
      .filter((entry): entry is { name: string; total: number; percentage: number } => entry !== null);
  };

  const chartDataState = allData.flatMap((item) => {
    const hasAnswers = item.answers && item.answers.length > 0;

    if (!hasAnswers) return [];

    if (item.templateType === "MULTIPLE_CHOICE") {
      return processMultipleChoiceData(item.answers);
    } else if (item.templateType === "SINGLE_CHOICE") {
      return processSingleChoiceData(item.answers);
    }

    return item.answers.map((answer) => {
      const rawText = getTranslatedText(answer.choices) || answer.answerMessage;
      const cleanText = he.decode(removeMarkdown(rawText));

      return {
        name: cleanText,
        total: answer.answerCount,
        percentage: answer.percentage || 0,
      };
    });
  });

  const truncateText = (text: string) => {
    if (!text) return "—";
    const singleLineText = text.replace(/[\r\n]+/g, " ").trim();

    if (singleLineText.length > 30) {
      return singleLineText.slice(0, 30) + "...";
    }

    return singleLineText;
  };

  return (
    <ResponsiveContainer width="100%" height={Math.max(400, chartDataState.length * 40)}>
      <BarChart data={chartDataState} layout="vertical">
        <CartesianGrid strokeDasharray="2" />
        <XAxis type="number" stroke="#000000" />
        <YAxis
          dataKey="name"
          type="category"
          stroke="#000000"
          width={150}
          tickFormatter={(value) => truncateText(value)}
        />
        <Tooltip
          wrapperStyle={{
            width: "200px",
            maxWidth: "200px",
          }}
          contentStyle={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          formatter={(value, name) => {
            if (name === "total") {
              const entry = chartDataState.find((item) => item.total === value);
              return [`${I18n.t("grade")}: ${value}, ${I18n.t("percentage")}: ${entry?.percentage.toFixed(1)}%`];
            }
            return value;
          }}
        />
        <Bar dataKey="total" fill="#00934F" barSize={23} radius={[0, 5, 5, 0]}>
          {chartDataState.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartWithToggleHorizontal;
