import { FC, useMemo } from "react";
import SelectFormItemComponent from "../form/items/select";
import { I18n } from "react-redux-i18n";
import { FactsEnum, getOperatorsList } from "../../types/subscriberConditionType";
import InputFormItemComponent from "../form/items/input";
import InputNumberFormItemComponent from "../form/items/inputNumber";
import DatepickerFormItemComponent from "../form/items/datepicker";
import { Form } from "antd";
import { mapToSelectItemCondition } from "./helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ContactsAttributeDatatypeEnum, ContactsAttributeType } from "../../types/ContactsAttributeType";
import useConditions from "../conditionsTag/ useConditions";

type Props = {
  readonly layout: "vertical" | "horizontal";
  readonly noStyle?: boolean;
  readonly paramAsText?: boolean;
} & ReturnType<typeof useConditions>;

const ConditionForm: FC<Props> = (props) => {
  const isHorizontal = useMemo(() => props.layout === "horizontal", [props.layout]);
  const attributes = useSelector((state: RootState) => state.contacts.attributesList) as ContactsAttributeType[];

  const InputField = useMemo(() => {
    const style: any = isHorizontal ? { minWidth: 183, maxWidth: 183, flex: 1, marginRight: 10 } : {};
    const selectedAttribute = attributes.find((attr) => attr.propertyKey === props.selectedFact);
    const validationRules = [];

    if (selectedAttribute) {
      switch (selectedAttribute.dataType) {
        case ContactsAttributeDatatypeEnum.EMAIL:
          validationRules.push({
            type: "email",
            message: I18n.t("emailError"),
          });
          break;
        case ContactsAttributeDatatypeEnum.PHONE:
          validationRules.push({
            pattern: new RegExp(/^[0-9][\s]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im),
            message: I18n.t("phoneError"),
          });
          break;
        case ContactsAttributeDatatypeEnum.INTEGER:
          validationRules.push({
            pattern: new RegExp(/^\d+$/),
            message: I18n.t("integerError"),
          });
          break;
        case ContactsAttributeDatatypeEnum.DECIMAL:
          validationRules.push({
            pattern: new RegExp(/^\d+(\.\d+)?$/),
            message: I18n.t("decimalError"),
          });
          break;
        case ContactsAttributeDatatypeEnum.DATE:
          break;
      }
    }

    if (props.selectedFact === FactsEnum.SUBSCRIBER_AGE) {
      return (
        <InputNumberFormItemComponent
          style={style}
          name={"value"}
          noStyle={props.noStyle}
          label={I18n.t("value")}
          placeHolder={I18n.t("enter")}
        />
      );
    } else if (props.selectedFact === FactsEnum.MSISDN_ACTIVATION_DATE) {
      return (
        <DatepickerFormItemComponent
          style={style}
          name={"value"}
          disablePast={false}
          noStyle={props.noStyle}
          label={I18n.t("value")}
        />
      );
    } else if (selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.DATE) {
      return (
        <DatepickerFormItemComponent
          style={style}
          name={"value"}
          disablePast={false}
          noStyle={props.noStyle}
          label={I18n.t("value")}
        />
      );
    } else if (
      selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.DECIMAL ||
      selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.INTEGER
    ) {
      return (
        <InputFormItemComponent
          style={style}
          name={"value"}
          label={I18n.t("value")}
          noStyle={props.noStyle}
          rules={validationRules}
          placeHolder={I18n.t("enter")}
        />
      );
    }

    return (
      <InputFormItemComponent
        style={style}
        name={"value"}
        label={I18n.t("value")}
        noStyle={props.noStyle}
        rules={validationRules}
        type={selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.PHONE ? "string" : undefined}
        prefix={selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.PHONE ? "+" : undefined}
        handlePhoneInputChange={selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.PHONE ? true : undefined}
      />
    );
  }, [props.selectedFact, props.noStyle, isHorizontal, attributes]);

  const selectItems = useMemo(
    () => props.conditions.map((condition) => mapToSelectItemCondition(condition, attributes)),
    [props.conditions, attributes],
  );

  return (
    <Form form={props.form} layout={props.layout} style={{ display: isHorizontal ? "flex" : undefined, gap: 10 }}>
      {props.paramAsText ? (
        <InputFormItemComponent
          name={"fact"}
          required={true}
          label={I18n.t("param")}
          placeHolder={I18n.t("enter")}
          style={isHorizontal ? { width: 300, flex: 2 } : {}}
        />
      ) : (
        <SelectFormItemComponent
          name={"fact"}
          required={true}
          allowClear={false}
          hasFeedback={false}
          noStyle={props.noStyle}
          label={I18n.t("param")}
          onSelect={props.factSelectHandler}
          placeHolder={I18n.t("select")}
          values={selectItems}
          style={isHorizontal ? { width: 300, flex: 2 } : {}}
        />
      )}

      <SelectFormItemComponent
        style={isHorizontal ? { width: 120, flex: 1 } : {}}
        values={props.paramAsText ? getOperatorsList() : props.operators}
        placeHolder={I18n.t("select")}
        label={I18n.t("operator")}
        noStyle={props.noStyle}
        hasFeedback={false}
        allowClear={false}
        name={"operator"}
        required={true}
      />
      {InputField}
    </Form>
  );
};

export default ConditionForm;
