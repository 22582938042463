import { QuestionType } from "../types/questionType";
import { RcFile } from "antd/es/upload";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { DistributionType, SourceEnum } from "../types/distributionType";

export const APP_PREFIX = window._env_.PUBLIC_URL;
export const APP_ALLOWED_ROLES = window._env_.ALLOWED_ROLES.split(",");
export const DEFAULT_VALIDATION_MESSAGE = "requiredField";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_ERROR_LABEL = "defaultError";
export const TEXT_LIGHT_COLOR = "rgba(185,183,183,0.52)";
export const MONTHLY_DATE_FORMAT = "DD MMMM YYYY";
export const MONTHLY_DATE_FORMAT_WITH_TIME = "DD MMMM YYYY HH:mm";
export const APP_PRIMARY_COLOR = "#00AA5C";

export const WELCOME_SMS_LABEL = "Welcome Message";
export const WELCOME_SMS_ORDER = 1;
export const MAX_QUESTIONS_QUANTITY = 100;

export const DEFAULT_FIRST_SMS = {
  order: WELCOME_SMS_ORDER,
  name: WELCOME_SMS_LABEL,
  kkText: "",
  enText: "",
  ruText: "",
  textTranslations: [],
  conditions: [],
  tags: [],
} as unknown as QuestionType;

export const DEFAULT_ANSWER_TEMPLATE = {
  addCustomAnswer: false,
  customAnswerTextKk: "",
  customAnswerTextRu: "",
  customAnswerTextEn: "",
  actionLabel: "Далее",
  csiParams: [],
  criteria: [],
  csiScale: undefined,
  type: undefined,
  answerChoices: [],
};

export const DEFAULT_QUESTION = {
  startBlock: false,
  endBlock: false,
  answerTemplate: DEFAULT_ANSWER_TEMPLATE,
  conditions: [],
  tags: [],
  enText: "",
  kkText: "",
  ruText: "",
  name: "",
};

type ValidateImgType = {
  readonly file: RcFile;
  readonly callback: () => void;
  readonly square: boolean;
  readonly maxHeight?: number;
  readonly maxWidth?: number;
  readonly minHeight?: number;
  readonly minWidth?: number;
};

export const validateImg = ({ file, callback, square, maxHeight, maxWidth, minHeight, minWidth }: ValidateImgType) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = async function (e) {
    const image = new Image();
    image.src = e?.target?.result as any;
    image.onload = function () {
      const { height, width } = image;
      if ((maxHeight && height > maxHeight) || (maxWidth && width > maxWidth)) {
        message.error(I18n.t("invalidImageSize"));
      } else if (square && height !== width) {
        message.error(I18n.t("differentAspectRatio"));
      } else if (minHeight && minWidth && (height < minHeight || width < minWidth)) {
        message.error(I18n.t("invalidImageSize"));
      } else {
        callback();
      }
    };
  };
};

export const DistributionInitialData = {
  sourceId: SourceEnum.CONTACTS,
  anonymous: false,
  schedule: {
    days: [1, 2, 3, 4, 5, 6, 7],
    fromTime: "00:00",
    toTime: "23:59",
  },
  dailyLimit: 100,
} as DistributionType;

export const getIframeValue = (url: string) => `<iframe src="${url}&variant=frame" height="100%" width="100%" />`;
