import React, { FC, useCallback, useState } from "react";
import { Tabs } from "antd";
import { Translate } from "react-redux-i18n";
import { TabKeysEnum } from "./helpers";
import ReviewTab from "./reviewTab/index";
import AnswersTab from "./answersTab/index";
import ResultsShare from "../../../../../components/ResultsShare";
import ReportsTab from "./reportsTab";

const items = (activeKey: TabKeysEnum) => {
  const baseItems = [
    { key: TabKeysEnum.REVIEW, label: <Translate value={"review"} />, children: <ReviewTab /> },
    {
      key: TabKeysEnum.ANSWERS,
      label: <Translate value={"answers"} />,
      children: <AnswersTab activeKey={activeKey} />,
    },
  ];

  if (process.env.REACT_APP_REPORTS_ENABLED === "true") {
    baseItems.splice(1, 0, {
      key: TabKeysEnum.REPORTS,
      label: <Translate value={"reports"} />,
      children: <ReportsTab />,
    });
  }

  return baseItems;
};

const ResultsPage: FC = () => {
  const [activeKey, setActiveKey] = useState<TabKeysEnum>(TabKeysEnum.REVIEW);
  const onTabChange = useCallback((key: string) => setActiveKey(key as TabKeysEnum), []);

  return (
    <Tabs
      items={items(activeKey)}
      activeKey={activeKey}
      onChange={onTabChange}
      className={"disabled-color"}
      tabBarExtraContent={<ResultsShare />}
    />
  );
};

export default ResultsPage;
