import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Tag } from "antd";
import { useActions } from "../../hooks/useActions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { conditionsAbbr, ConditionType, SubscriberCondition } from "../../types/subscriberConditionType";
import { PlusOutlined } from "@ant-design/icons";
import ConditionModal from "../condition/conditionModal";
import { I18n, Translate } from "react-redux-i18n";
import useSurveyDisabled from "../../hooks/useSurveyDisabled";
import ConditionInline from "../condition/conditionInline";
import Divider from "../divider";
import { ContactsAttributeType } from "../../types/ContactsAttributeType";
import { getConditions } from "../../store/slices/conditions/conditionsService";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../utils";

type Props = {
  readonly type: "questions" | "distributions";
  readonly title?: string;
  readonly noStyle?: boolean;
  readonly variant?: "modal" | "inline";
};

const SubscriberProfileFiltersComponent: FC<Props> = (props) => {
  const { type, title = I18n.t("bySubscriberProfile"), noStyle, variant = "modal" } = props;
  const { updateCurrentQuestion, updateCurrentDistribution } = useActions();
  const dispatch = useDispatch<AppDispatch>();
  const [showAddModal, setShowAddModal] = useState(false);

  const subscriberConditions: Array<SubscriberCondition> = useSelector(
    (state: RootState) =>
      (state as any)[type]?.current?.[type === "questions" ? "conditions" : "subscriberConditions"] || [],
  );
  const attributes: ContactsAttributeType[] = useSelector(
    (state: RootState) => state.contacts.attributesList,
  ) as ContactsAttributeType[];
  const conditionsList: Array<ConditionType> = useSelector((state: RootState) => state.conditions.list);

  const disabled = useSurveyDisabled();

  useEffect(() => {
    dispatch(getConditions());
  }, [dispatch, conditionsList]);

  const onSetConditions = useCallback(
    (_conditions: Array<SubscriberCondition>) => {
      if (type === "questions") {
        return updateCurrentQuestion({ conditions: _conditions });
      }
      updateCurrentDistribution({ subscriberConditions: _conditions });
    },
    [type, updateCurrentQuestion, updateCurrentDistribution],
  );

  const deleteCondition = useCallback(
    (index: number) => {
      onSetConditions(subscriberConditions.filter((_, i) => i !== index));
    },
    [subscriberConditions, onSetConditions],
  );

  const onShowAddForm = useCallback(() => {
    setShowAddModal((prev) => !prev);
  }, [setShowAddModal]);

  const onConditionSave = useCallback(
    (condition: SubscriberCondition) => {
      onSetConditions([...subscriberConditions, condition]);
    },
    [subscriberConditions, onSetConditions],
  );

  return (
    <div className={"conditions-tag"}>
      {variant === "modal" ? (
        <ConditionModal show={showAddModal} onHide={onShowAddForm} onFinish={onConditionSave} />
      ) : (
        <ConditionInline onAdd={onConditionSave} />
      )}
      <Divider />
      <Form.Item noStyle={noStyle} label={title}>
        {subscriberConditions.map((condition, index) => {
          const attribute = attributes.find((attr) => attr.propertyKey === condition.fact);
          const factLabel = attribute?.description;
          const operatorLabel = conditionsAbbr()[condition.operator] || condition.operator;

          let displayValue = condition.value;
          if (attribute?.dataType === "DATE" || condition.fact === "MSISDN_ACTIVATION_DATE") {
            try {
              displayValue = dayjs(condition.value).format(DATE_FORMAT);
            } catch (e) {
              console.error("Error formatting date:", e);
            }
          }

          return (
            <Tag key={condition.value + index} closable={!disabled} onClose={() => deleteCondition(index)}>
              {factLabel}:{" "}
              <b>
                {operatorLabel} {displayValue}
              </b>
            </Tag>
          );
        })}
        {variant === "modal" && <Action disabled={disabled} onShowAddForm={onShowAddForm} />}
      </Form.Item>
    </div>
  );
};

export default SubscriberProfileFiltersComponent;

type ActionProps = {
  readonly disabled: boolean;
  readonly onShowAddForm: () => void;
};
const Action: FC<ActionProps> = ({ disabled, onShowAddForm }) => {
  if (disabled) {
    return (
      <Button type={"dashed"} size={"small"} disabled>
        <PlusOutlined /> <Translate value={"addFilter"} />
      </Button>
    );
  }

  return (
    <Tag onClick={onShowAddForm} className={"tag-add-btn"}>
      <PlusOutlined /> <Translate value={"addFilter"} />
    </Tag>
  );
};
