import { useCallback, useMemo, useState } from "react";
import { conditionsAbbr, FactsEnum, SubscriberCondition } from "../../types/subscriberConditionType";
import { LabelValue } from "../../types/labelValue";
import store from "../../store";
import { Form } from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../utils";
import { ContactsAttributeDatatypeEnum } from "../../types/ContactsAttributeType";

export const useConditions = (
  handleFinish: (condition: SubscriberCondition, groupId?: number) => void,
  groupId?: number,
  handleHide?: () => void,
) => {
  const [selectedFact, setSelectedFact] = useState<FactsEnum | undefined>();
  const [operators, setOperators] = useState<Array<LabelValue>>([]);
  const conditions = store.getState().conditions.list;
  const [form] = Form.useForm<SubscriberCondition>();

  const factSelectHandler = useCallback(
    (fact: any) => {
      setSelectedFact(fact);
      form.resetFields(["value", "operator"]);
      const foundCondition = conditions.find((c) => c.fact === fact);
      setOperators(
        foundCondition?.operators.map((str) => ({
          value: str,
          label: conditionsAbbr()[str],
        })) || [],
      );
    },
    [setSelectedFact, form, setOperators, conditions],
  );

  const onClear = useCallback(() => {
    setSelectedFact(undefined);
    form.resetFields();
    if (handleHide) {
      handleHide();
    }
  }, [form, setSelectedFact]);

  const onSave = useCallback(() => {
    form
      .validateFields()
      .then((condition) => {
        let formattedCondition = { ...condition };

        if (selectedFact === FactsEnum.MSISDN_ACTIVATION_DATE) {
          formattedCondition.value = dayjs(condition.value).format(DATE_FORMAT);
        } else if (condition.value && typeof condition.value === "object" && "isDayjsObject" in condition.value) {
          formattedCondition.value = dayjs(condition.value).format(DATE_FORMAT);
        }

        const attributes = store.getState().contacts.attributesList;
        const selectedAttribute = attributes.find((attr) => attr.propertyKey === selectedFact);

        if (selectedAttribute?.dataType === ContactsAttributeDatatypeEnum.DECIMAL) {
          if (typeof condition.value === "string") {
            const parsedValue = parseFloat(condition.value);
            if (!isNaN(parsedValue)) {
              formattedCondition.value = parsedValue.toString();
            }
          }
        }

        handleFinish(formattedCondition, groupId);
        onClear();
      })
      .catch(console.log);
  }, [form, selectedFact, handleFinish, onClear]);

  const value = useMemo(
    () => ({
      selectedFact,
      setSelectedFact,
      operators,
      setOperators,
      conditions,
      form,
      factSelectHandler,
      onClear,
      onSave,
    }),
    [selectedFact, setSelectedFact, operators, setOperators, conditions, form, factSelectHandler, onClear, onSave],
  );

  return { ...value };
};

export default useConditions;
