import { ConditionType } from "../../types/subscriberConditionType";
import { ContactsAttributeType } from "../../types/ContactsAttributeType";

export const mapToSelectItemCondition = (condition: ConditionType, attributes: ContactsAttributeType[]) => {
  const attribute = attributes.find((attr) => attr.propertyKey === condition.fact);

  return {
    key: condition?.conditionDescId,
    value: condition.fact,
    label: attribute?.description,
  };
};
