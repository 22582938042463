export const KzTranslations = {
  surveys: "Сауалнамалар",
  survey: "Сауалнама",
  questions: "Сұрақтар",
  question: "Сұрақ",
  answer: "Жауап",
  settings: "Параметрлер",
  create: "Жасау",
  cancel: "Болдырмау",
  save: "Сақтау",
  show: "Көру",
  back: "Артқа",
  delete: "Жою",
  tags: "Тегтер",
  select: "Таңдаңыз",
  enter: "Енгізіңіз",
  oneOf: "Біреуі",
  general: "Жалпы",
  schedule: "Кесте",
  respondents: "Алушылар",
  search: "Іздеу",
  generateQr: "QR жасаңыз",
  searchBySurveys: "Сауалнама іздеу",
  questionnaireQuestions: "Сауалнама сұрақтары",
  personalization: "Тіркеу",
  integrations: "Интеграциялар",
  results: "Нәтижелер",
  date: "Күні",
  time: "Уақыт",
  days: "Апта күндері",
  monday: "Дүйсенбі",
  tuesday: "Сейсенбі",
  wednesday: "Сәрсенбі",
  thursday: "Бейсенбі",
  friday: "Жұма",
  saturday: "Сенбі",
  sunday: "Жексенбі",
  mondayShortLabel: "Дб",
  tuesdayShortLabel: "Сб",
  wednesdayShortLabel: "Ср",
  thursdayShortLabel: "Бс",
  fridayShortLabel: "Жм",
  saturdayShortLabel: "Сн",
  sundayShortLabel: "Жб",
  today: "Бүгін",
  dailyDistributionSize: "Күнделікті жіберу мөлшері",
  exclusionDates: "Ерекше күндер",
  exclusionDatesHelpTxt:
    "Бұл функция жазылушыларға сауалнама жіберуге тыйым салынған күндерді белгілеуге мүмкіндік береді.",
  apiKey: "API-Кілті",
  apiKeyDeleteConfirmation: "API кілтін жойғыңыз келетініне сенімдісіз бе ？",
  apiKeyHelpTxt:
    "Функция сауалнамалар жіберу үшін API сұрауларына рұқсат беру үшін қажет API кілттерін басқаруға мүмкіндік береді.",
  description: "Сипаттама",
  actions: "Әрекеттер",
  action: "Әрекет",
  apiKeyCopiedToCopyBoard: "API-Кілті алмасу буферіне көшірілді.",
  notOneOf: "Кез келген басқа",
  createSurvey: "Сауалнама жасаңыз",
  fromTemplate: "Шаблоннан",
  surveyName: "Сауалнама атауы",
  surveyNameTooltip: "Сауалнама атауы респондент үшін әрбір сауалнама бетінде тақырып ретінде көрсетіледі.",
  type: "Түрі",
  recipientsSource: "Алушы көзі",
  respondentsListSource: "Респонденттердің тізімінің көзі",
  created: "Құрылды",
  createDate: "Құрылған күні",
  endDate: "Аяқтау күні",
  startDate: "Басталатын күн",
  status: "Күй",
  subscribersDb: "Контактілер",
  file: "Файл",
  startSurvey: "Сауалнаманы бастау",
  startSurveyConfirmation: "Сауалнаманы бастағыңыз келетініне сенімдісіз бе ？",
  endSurvey: "Сауалнаманы аяқтау",
  endSurveyConfirmation: "Сауалнаманы толтырғыңыз келетініне сенімдісіз бе ？",
  pauseSurvey: "Сауалнаманы кідірту",
  pauseSurveyConfirmation: "Сауалнаманы кідіртуді қалайсыз ба ？",
  filtration: "Сүзу",
  bySubscriberProfile: "Жазылушы профилі",
  bySurveyProps: "Сауалнама параметрлері",
  addFiltersGroup: "Сүзгі тобын қосыңыз",
  countSubscribers: "Үлгідегі респонденттердің санын есептеңіз",
  selectFile: "Файлды таңдаңыз",
  filtersGroupHelpText: "НЕМЕСЕ логикасына сәйкес жұмыс істейтін бірнеше сүзгі топтарын жасауға болады",
  questionName: "Сұрақтың аты",
  template: "Үлгі",
  noData: "Мәлімет жоқ",
  createCopy: "Көшіру жасау",
  copyLink: "Сілтемені көшіру",
  createDuplicate: "Көшірме жасау",
  createDuplicateConfirmation: "Қайталанатын сауалнама жасағыңыз келетініне сенімдісіз бе ？",
  deleteQuestion: "Сұрақты жою",
  deleteQuestionConfirmation: "Сұрақты жойғыңыз келетініне сенімдісіз бе ？",
  edit: "Өңдеу",
  addTag: "Белгі қосу",
  addFilter: "Сүзгіні қосыңыз",
  questionText: "Сұрақ мәтіні ",
  questionTextOnEn: "Ағылшын тіліндегі сұрақ мәтіні",
  questionTextOnKk: "Қазақ тіліндегі сұрақ мәтіні",
  questionTextOnRu: "Орыс тіліндегі сұрақ мәтіні",
  questionTextOnFrench: "Француз тіліндегі сұрақ мәтіні",
  questionTextOnTurkish: "Түрік тіліндегі сұрақ мәтіні",
  questionTextOnUzbek: "Өзбек тіліндегі сұрақ мәтіні",
  onEn: "Ағылшынша",
  onKk: "Қазақ тілінде",
  onRu: "Орыс тілінде",
  onFrench: "Француз тілінде",
  onTurkish: "Түрік тілінде",
  onUzbek: "Өзбек тілінде",
  useCondition: "Қолдану шарты",
  singleChoice: "Тізімнің бірі",
  multipleChoice: "Тізімнен бірнешеу",
  textAnswer: "Мәтіндік жауап",
  infoBlock: "Ақпарат блогы",
  endBlock: "Соңғы блок",
  matrixCsi: "CSI (Клиенттердің Қанағаттану Индексі)",
  npsRatingScale: "Рейтинг",
  other: "Басқа",
  add: "Қосу",
  params: "Параметрлер",
  param: "Параметр",
  criteria: "Критерийлер",
  gradeTxt: "Бағалау мәтіні",
  gradeTxtOnEn: "Ағылшын тіліндегі бағалау мәтіні",
  gradeTxtOnKk: "Бағалау мәтіні қазақ тілінде",
  gradeTxtOnRu: "Орыс тіліндегі бағалау мәтіні",
  gradeTxtOnFrench: "Француз тіліндегі бағалау мәтіні",
  gradeTxtOnTurkish: "Түрік тіліндегі бағалау мәтіні",
  gradeTxtOnUzbek: "Өзбек тіліндегі бағалау мәтіні",
  balls: "Ұпай",
  yes: "Иә",
  no: "Жоқ",
  yesNo: "Иә / Жоқ",
  ratingScale: "Бағалау шкаласы",
  blockType: "Блок түрі",
  information: "Ақпараттық",
  final: "Финал",
  answerType: "Жауап түрі",
  inputField: "Кіру өрісі",
  inputFieldFormat: "Енгізу өрісінің пішімі",
  oneLine: "Бір лайнер",
  multiLine: "Көпжолақты",
  phone: "Телефон",
  answerRequired: "Жауап қажет",
  icon: "Белгіше",
  textValues: "Мәтін мәндері",
  grade: "Баға",
  min: "Мин.",
  max: "Макс.",
  logo: "Логотип",
  logoMinSizeHelpText: "Мин. логотип өлшемі: ",
  logoMaxSizeHelpText: "Макс. логотип өлшемі: ",
  logoMaxWeightHelpText: "Макс. файл салмағы: ",
  faviconMaxSizeHelpText: "Макс. фавикон өлшемі: ",
  themeColor: "Корпоративтік түс",
  surveyMetaName: "Браузер қойындысының атауы",
  surveyMetaDescription: "Сауалнаманың сипаттамасы",
  next: "Әрі қарай",
  answerVariable: "Мүмкін жауап",
  personalizationHelpText: "Сауалнамаларыңыз үшін дизайнды таңдаңыз. Логотип жоғарғы сол жақ бұрышта көрсетіледі.",
  errorLabel: "Қате орын алды",
  errorWhileSaving: "Сақтау кезінде қате орын алды ",
  requiredMessage: "Толтыру үшін қажет",
  successful: "Сәтті",
  logout: "Шығу",
  userName: "Қолданушының аты",
  password: "Құпия сөз",
  pleaseEnter: "Өтінемін, кіріңіз",
  signIn: "Кіру",
  authError: "Кіру ақпараты жарамсыз. Пайдаланушы табылмады немесе құпия сөз қате.",
  deleteSurvey: "Сауалнаманы жою",
  deleteSurveyConfirmation: "Сауалнаманы жойғыңыз келетініне сенімдісіз бе ？",
  new: "Жаңа",
  draft: "Жоба",
  active: "Белсенді",
  paused: "Кідіртуде",
  finished: "Аяқталды",
  deleted: "Жойылған",
  reset: "Қалпына келтіру",
  surveyType: "Сауалнама түрі",
  subscribers: "Респонденттер",
  areYouSure: "Сіз сенімдісіз бе ？",
  qrCode: "QR-коды",
  fillRequiredFields: "Міндетті өрістерді толтырыңыз",
  requiredField: "Толтыру қажет",
  useDefaultColor: "Әдепкі түсті пайдаланыңыз",
  maxCharacters: "Макс. кейіпкерлер: 50",
  changesSaved: "Өзгерістер сақталды",
  acceptTypesFormat: "Қолдау көрсетілетін файл пішімдері",
  metaDescriptionDefaultText: "Сізді сілтеме бойынша сауалнамаға қатысуға шақырамыз",
  selectLogo: "Логотипті таңдаңыз",
  selectFavicon: "Фавиконды таңдаңыз",
  selectIcon: "Белгішені таңдаңыз",
  callOn: "Оқиға",
  method: "Әдіс",
  deleteWebhook: "Webhook жою",
  deleteWebhookConfirmation: "Webhook қолданбасын жойғыңыз келетініне сенімдісіз бе ？",
  createWebhook: "Webhook жасау",
  request: "Сұрау",
  authorization: "Рұқсат",
  authorizationInfo: "Авторизация деректері",
  headers: "Атаулар",
  key: "Кілт",
  value: "Мағынасы",
  format: "Формат",
  requestBody: "Сұраныс органы",
  enterPassword: "Құпия сөзді еңгізіңіз",
  enterOldPassword: "Ескі құпия сөзді енгізіңіз",
  enterNewPassword: "Жаңа құпия сөзді енгізіңіз",
  confirmPassword: "Құпия сөзді растаңыз",
  confirm: "Растау",
  passwordChanged: "Пароль успешно изменён",
  oldPassword: "Ескі Құпия сөз",
  newPassword: "Жаңа құпия",
  passwordsNotEqual: "Құпия сөздер сәйкес келмейді",
  apiKeys: "API кілттері",
  exceptionDates: "Ерекше күндер",
  selectDate: "Күнді таңдаңыз",
  deleteDate: "күнін жою",
  deleteDateConfirmation: "Күнді жойғыңыз келетініне сенімдісіз бе ？",
  cancelAllChangesConfirmation: "Барлық өзгерістерді қайтарғыңыз келетініне сенімдісіз бе ？",
  createQuestion: "Сұрақ құру",
  enterID: "идентификаторды енгізіңіз",
  byDefault: "Әдепкі",
  close: "Жабық",
  surveyStarting: "Сауалнаманың басталуы",
  surveyEnding: "Сауалнаманың соңы",
  dbSubscribersCount: "Деректер базасынан алушылардың саны",
  sbSubscribersHelpTxt: "Поштаны алушылардың саны",
  respondentsCount: "Респонденттердің саны",
  respondentsHelpTxt: "Сауалнама ашқан немесе QR кодын сканерлеген респонденттердің саны",
  participantsCount: "Қатысушылар саны",
  participantsHelpTxt: "Кем дегенде бір сұраққа жауап берген респонденттердің саны",
  endedSurveyCount: "Сауалнаманы аяқтады",
  endedSurveyHelpTxt: "Барлық сұрақтарға жауап берген респонденттердің саны",
  download: "Жүктеп алу",
  downloadCsv: "CSV жүктеп алыңыз",
  downloadExcel: "Excel жүктеп алыңыз",
  answerText: "Жауап мәтіні",
  answerTextOnEn: "Жауап мәтіні ағылшын тілінде",
  answerTextOnKk: "Қазақ тілінде жауап мәтіні",
  answerTextOnRu: "Орыс тілінде жауап мәтіні",
  answerTextOnFrench: "Француз тілінде жауап мәтіні",
  answerTextOnTurkish: "Түрік тілінде жауап мәтіні",
  answerTextOnUzbek: "Өзбек тілінде жауап мәтіні",
  text: "Мәтін",
  textOnEn: "Ағылшынша мәтін",
  textOnKk: "Қазақ тіліндегі мәтін",
  textOnRu: "Орыс тіліндегі мәтін",
  textOnFrench: "Француз тіліндегі мәтін",
  textOnTurkish: "Түрік тіліндегі мәтін",
  textOnUzbek: "Өзбек тіліндегі мәтін",
  selectFromList: "Тізімнен таңдаңыз",
  operator: "Оператор",
  before: "Бұрын",
  after: "Кейін",
  age: "Жасы",
  brand: "Бренд",
  name: "Аты",
  gender: "Еден",
  activationDate: "Іске қосу күні",
  title: "Аты",
  titleOnEn: "Ағылшынша атау",
  titleOnKk: "Аты қазақша",
  titleOnRu: "Орысша аты",
  titleOnFrench: "Французша аты",
  titleOnTurkish: "Түрікше есім",
  titleOnUzbek: "Өзбекше аты",
  dateCreatedSuccessfully: "Жасалған күні",
  dateDeleted: "Жойылған күні",
  apiKeyCreated: "API кілті жасалды",
  apiKeyDeleted: "API кілті жойылды",
  questionCreated: "Сұрақ құрылды",
  questionDeleted: "Сұрақ жойылды",
  surveyCreated: "Сауалнама жасалды",
  surveyDeleted: "Сауалнама жойылды",
  surveyPaused: "Сауалнама кідіртілді",
  surveyEnded: "Сауалнама аяқталды",
  surveyDuplicated: "Сауалнама көшірілді",
  webhookCreated: "Webhook жасалды",
  webhookDeleted: "Webhook жойылды",
  qrCreated: "QR коды жасалды",
  fileDeleted: "Файл жойылды",
  fileUploaded: "Файл жүктелді.",
  fileUpload: "Файлды жүктеп салу",
  fileDownloaded: "Файл жүктелді",
  subscribersCountInFile: "Файлдағы респонденттердің саны:",
  defaultError: "Қате орын алды",
  surveyStarted: "Сауалнама басталды",
  whileSaving: "сақтау кезінде",
  fileAcceptType: "Қолдау көрсетілетін файл пішімі",
  pleaseEnterUserName: "Пайдаланушы атын енгізіңіз",
  pleaseEnterPassword: "Құпия сөзді енгізіңіз",
  web: "Веб",
  sms: "СМС",
  changePassword: "Құпия сөзді өзгерту",
  passwordChange: "Пин кодын өзгерту",
  passwordChangeConfirm: "Құпия сөзіңізді өзгерткіңіз келетініне сенімдісіз бе ？",
  thisQuestionIsCondition: "Бұл сұрақ басқа сұрақты көрсету шарты болып табылады",
  maxFileWeightError: "Максималды файл өлшемі: 100КБ",
  invalidImageSize: "Кескін өлшемі жарамсыз",
  differentAspectRatio: "Әр түрлі арақатынас",
  rating: "Рейтинг",
  slider: "Слайдер",
  dropdown: "Түсіп қалу",
  radioButtons: "Радио түймелері",
  loyaltyIndex: "Промоутердің Таза Ұпайы (NPS®)",
  customSlider: "Арнаулы сырғытпа",
  selectType: "Түрді таңдаңыз",
  maxValue: "Максималды мән",
  selectMaxValue: "Макс. мәнді таңдаңыз",
  languages: "Тілдер",
  contacts: "Контактілер",
  contactsHelpTxt: "Контактілер",
  fact: "Факт",
  russian: "Орыс",
  kazakh: "Қазақ",
  english: "Ағылшын",
  turkish: "Түрік",
  uzbek: "Өзбек",
  french: "Француз",
  code: "Код",
  deleteLanguageConfirmation: "Тілді өшіргіңіз келетініне сенімдісіз бе ？",
  languagesHelpTxt: "Сауалнамаға қосу үшін қолжетімді тілдер тізімі.",
  languageCreated: "Тіл құрылды",
  languageDeleted: "Тіл жойылды",
  prolongSurvey: "Сауалнаманы кеңейту",
  clear: "Тазала",
  forgotPassword: "Құпия сөзіңізді ұмыттыңыз ба？",
  register: "Тіркелу",
  backToLogin: "Жүйеге оралу",
  phoneNumber: "Телефон нөмірі",
  invalidPhoneNumber: "Жарамсыз телефон нөмірі",
  fullName: "Толық аты",
  email: "Электрондық пошта",
  registration: "Тіркеу",
  passwordRecovery: "Құпия сөзді қалпына келтіру",
  codeValidation: "Кодты тексеру",
  sendNewPassword: "Жаңа құпия сөзді жіберу",
  invalidEmail: "Қате электрондық пошта",
  searchByTags: "Тегтер бойынша іздеу",
  closeTabText: "Сауалнама аяқталды, браузеріңіздің функцияларын пайдаланып қойындыны жабыңыз.",
  yourAnswer: "Сіздің жауабыңыз...",
  registrationSuccess: "Тіркеу сәтті аяқталды",
  registrationError: "Тіркеу қатесі",
  enterValidationCode: "Аалынған кодты енгізіңіз",
  codeValidationError: "Жарамсыз растау коды енгізілді.",
  createdAt: "Құрылған: ",
  questionsDragDropWarningText: "Егер сұрақта көрсету шарттары болса, сұрақ жылжытылғанда олар жұмыс істемеуі мүмкін",
  youForgotEndBlock: "Ой! Сіз соңғы блокты ұмытып кеткен сияқтысыз",
  addAutomaticallyAndStart: "Автоматты түрде қосып, сауалнаманы бастаңыз",
  backToQuestionsTable: "Сұрақтар кестесіне қайта келу",
  pageName: "Бет тақырыбы",
  pageDisplayCondition: "Бет жағдайы",
  addCondition: "Шартты қосыңыз",
  removeCondition: "Шартты жою",
  addQuestion: "Сұрақ қосыңыз",
  questionnaire: "Анкета",
  answers: "Жауаплар",
  variant: "Опция",
  addAnswerVariant: "Жауап опциясын қосыңыз",
  areYouSureYouWantToLeaveEditPage: "Өңдеу бетінен шынымен шыққыңыз келе ме ？",
  youWillLostYourChanges: "Барлық сақталмаған деректер жоғалады.",
  checkYourMailMessage: "Сізге электрондық хат жіберілді, электрондық поштаңызды тексеріңіз.",
  passwordReset: "Құпия сөзді қалпына келтіру",
  linkExpiredMessage: "Сілтеме ескірген сияқты. Қайтадан байқап көріңіз.",
  mailSendLimitReachedError: "Лимиттен асып кетті. Кейінірек көріңіз.",
  content: "Мазмұны",
  includeYourChoiceInAnswers: "Жауаптарда «өз таңдауыңызды» қосыңыз",
  titleForYourChoice: "«Сіздің таңдауыңыз» тақырыбы",
  addParam: "Параметрді қосыңыз",
  addCriteria: "Критерийлерді қосу",
  visualRow: "Көрнекі диапазон",
  questionDisplayLogic: "Сұрақты көрсету логикасы",
  addConditionAccordingToPrevQuestion: "Алдыңғы сұраққа жауапқа байланысты сұрақты көрсету шартын қосыңыз",
  displayIfAnswerOn: "Жауап болса көрсетіңіз: ",
  displayOnlyContactsWhich: "Мыналары бар контактілерге ғана көрсету: ",
  newQuestion: "Жаңа сұрақ",
  youHaveUncompletedNewQuestionWarning: "Сақталмаған жаңа сұрағыңыз бар",
  infoBlockText: "Ақпарат блогының мәтіні",
  iAgreeWith: "Мен ",
  termsOfUse: "Пайдаланушы Келісімімен келісемін",
  limitReached: "Лимиттен асып кетті",
  change: "Өзгерту",
  identifier: "Идентификатор",
  size: "Өлшем",
  copyImage: "Кескінді көшіру",
  sendImage: "Суретті жіберу",
  successCopiedToClipBoard: "Буферге көшірілді",
  accessDenied: "Қосылуға мүмкіндік жоқ.",
  iin: "ЖСН",
  addCustomRegexp: "Тұрақты өрнекті қолмен қосыңыз",
  regexp: "Тұрақты өрнек",
  enterRegexp: "Тұрақты өрнекті енгізіңіз",
  validateResponse: "Жауапты растау",
  documentation: "Құжаттама",
  defaultLabel: "Әдепкі",
  emailProvidersMainDescription:
    "Тізімде ұсынылғандардан провайдердің электрондық поштасын қосуға болады, сонда оны сауалнама ішінде таңдауға болады.",
  emailProviders: "E-mail провайдерлері",
  providerDescription: "Провайдер сипаттамасы",
  connectionName: "Қосылым атауы",
  provider: "Провайдер",
  addpostmarkProvider: "Postmark провайдерін қосыңыз",
  changepostmarkProvider: "Postmark провайдерін қосыңыз",
  addsmtpProvider: "SMTP провайдерін қосыңыз",
  changesmtpProvider: "SMTP провайдерін қосыңыз",
  providersName: "Провайдердің аты",
  emailProviderDeleted: "E-mail провайдері жойылды",
  emailProviderCreated: "E-mail провайдері құрылды",
  emailProviderChanged: "E-mail провайдері өзгерді",
  deleteEmailProviderConfirmation: "E-mail провайдерін жойғыңыз келетініне сенімдісіз бе ？",
  titleFont: "Тақырып шрифті",
  textFont: "Мәтін шрифті",
  selectFont: "Қаріпті таңдаңыз",
  distributionAndNotification: "Ақпараттық бюллетень және хабарландырулар",
  distributionList: "Жіберу тізімі",
  uniqueUrlsAndQrCodes: "Бірегей сауалнама URL мекенжайлары және QR-кодтары",
  distributionDate: "Жіберу күні",
  distributionChannel: "Тарату арнасы",
  respondentsSource: "Респонденттердің көзі",
  uniqueUrlParam: "Бірегей URL параметрі",
  surveysURL: "Сауалнама URL мекенжайы",
  fileFromDisc: "Дискіден файл",
  from: "бастап ",
  to: "дейін ",
  distributionDeleteConfirmation: "Ақпараттық бюллетеньді жойғыңыз келетініне сенімдісіз бе ？",
  creatingDistribution: "Пошталық тізім құру",
  editingDistribution: "Ақпараттық бюллетеньді өңдеу",
  distributionStartConfirmation: "Жіберуді бастағыңыз келетініне сенімдісіз бе ？",
  distributionFinishConfirmation: "Хабарламаны аяқтағыңыз келетініне сенімдісіз бе ？",
  distributionPauseConfirmation: "Ақпараттық бюллетеньді кідірту керек екеніне сенімдісіз бе ？",
  attributeContainingSendingAddress: "Жіберу мекенжайын қамтитын төлсипат",
  attributeContainingSendingAddressHelpText:
    "Алушының телефон нөмірі немесе электрондық пошта мекенжайы бар контакт атрибуты.",
  subscriberSelectionLogic: "Жазылушыны іріктеу логикасы",
  anonymousSurvey: "Анонимді сауалнама",
  fill: "Толтыру",
  qrAndUrlCreating: "QR/URL құру",
  shareQrAnsUrl: "QR/URL мекенжайын бөлісіңіз",
  copy: "Көшіру",
  integrationsDocHelpText: 'API авторизация кілттерін жасау үшін "Параметрлер/API кілттері" бөлімін қараңыз',
  triggerSurveySettings: "Іске қосылған сауалнамаларды орнату",
  triggerCondition: "Триггер шарттары",
  areYouSureYouWantToDeleteFiltersGroup: "Сүзгі тобын жойғыңыз келетініне сенімдісіз бе ？",
  saveChanges: "Өзгерістерді сақтау",
  qrCodeDeleted: "QR коды жойылды",
  qrCodeUpdated: "QR коды өзгертілді",
  qrCodeCreated: "QR коды жасалды",
  addFromFile: "Файлдан қосу",
  addContact: "Контакт қосыңыз",
  contactsUploadCsvHelpText: "csv (мысалы, форматты қараңыз)",
  contactsUploadXlsxHelpText: "xlsx (мысалы, форматты қараңыз)",
  contactsUploading: "Контактілер жүктелуде",
  filesType: "Файл түрі",
  source: "Дереккөз",
  upload: "Жүктеп алу",
  contactDeleted: "Контакт жойылды",
  contactUpdated: "Контакт өзгертілді",
  contactCreated: "Контакт құрылды",
  noFileSelected: "Файлды таңдаңыз",
  updateAt: "Жаңарту күні",
  attributes: "Атрибуттар",
  keyField: "негізгі өріс",
  warningWhenUploadingFile: {
    title: "Файлды жүктеп алмас бұрын ескерту",
    subTitle: "Төмендегі шарттар орындалғанына көз жеткізіңіз:",
    first: "1. Файл пішімі файл түріне сәйкес келеді",
    second: "2. Файл оқуға жарамды",
    third: "3. Файл өлшемі 1 МБ аспайды",
    fourth: "4. Файл пішімі таңдалған түрге сәйкес келеді",
  },
  watchShort: "қара",
  exampleHere: "мұнда мысал",
  fileUploadingError: "Файлды жүктеп салу қатесі",
  deactivated: "Өшірілген",
  atLeastOneDistributionRequired: "Кем дегенде бір пошта жіберу қажет",
  smsProviders: "SMS провайдерлері",
  smsProvidersMainDescription: "Сауалнама ішінде кейінірек таңдау үшін SMS провайдерін қосуға болады.",
  sender: "Жіберуші",
  surveyActivated: "Сауалнама іске қосылды",
  surveyDeactivated: "Сауалнама өшірілді",
  activateSurvey: "Іске қосу",
  deactivateSurvey: "Өшіру",
  activateSurveyConfirmation: "Сауалнаманы белсендіргіңіз келетініне сенімдісіз бе ？",
  deactivateSurveyConfirmation: "Сауалнаманы өшіргіңіз келетініне сенімдісіз бе ？",
  distributionFinished: "Ақпараттық бюллетень аяқталды",
  distributionPaused: "Ақпараттық бюллетень тоқтатылды",
  distributionStarted: "Ақпараттық бюллетень басталды",
  distributionUpdated: "Ақпараттық бюллетень өзгертілді",
  distributionCreated: "Ақпараттық бюллетень жасалды",
  distributionDeleted: "Ақпараттық бюллетень жойылды",
  addAutomaticallyAndStartDistribution: "Автоматты түрде қосып, жіберуді бастаңыз",
  atLeastOneQuestionIsRequired: "Пошта жіберуді бастау үшін сауалнамада кем дегенде бір сұрақ жасау керек",
  distributionsAreTemporaryPausedHelpTxt:
    "Назар аударыңыз! Сауалнаманың өшірілуіне байланысты жіберулер уақытша тоқтатылды.",
  allTempPausedDistributionsWillContinue: "Барлық уақытша тоқтатылған жөнелтілімдер қайта жалғасады.",
  allDistributionsWillBePausedTemporary: "Барлық жөнелтілімдер уақытша тоқтатылады.",
  contactsSettings: "Байланыс параметрлері",
  addAttribute: "Атрибут қосу",
  attributeName: "Атрибут атауы",
  datatype: "Деректер түрі",
  whoAdded: "Қосқан",
  whenAdded: "Қосылған кезде",
  attributeDeleted: "Атрибут жойылды",
  attributeCreated: "Атрибут жасалды",
  attributeUpdated: "Атрибут өзгертілді",
  attributeDeleteConfirmation: "Атрибутты жойғыңыз келетініне сенімдісіз бе ？",
  newAttribute: "Атрибуттар",
  contact: "Байланыс",
  contactsSettingsHelpTxt: "Контактілерге қосу үшін қолжетімді төлсипаттардың тізімі.",
  newContact: "Контактілер",
  updateContact: "Контактіні өңдеу",
  labelMustBeUnique: "Property key бірегей болуы керек",
  contactDeleteConfirmation: "Контактіні жойғыңыз келетініне сенімдісіз бе ？",
  signInWithGoogle: "Google арқылы жүйеге кіріңіз",
  review: "Қарау",
  reports: "Есептер",
  answersByDays: "Күні бойынша жауаптар",
  loading: "Жүктелуде",
  generalStatistics: "Жалпы статистика",
  viewed: "Қаралды",
  surveysStarted: "Сауалнамалар басталды",
  avgFinishTime: "Аяқтаудың орташа уақыты",
  finishedProportion: "Аяқталғанның үлесі",
  finishedCount: "Аяқталды",
  avgTime: "Орташа уақыт",
  byDeviceType: "Құрылғы түрі бойынша",
  byLanguage: "Тіл бойынша",
  byDistributionType: "Пошта түрі бойынша",
  numberOfInteractionsByCategory: "Санат бойынша өзара әрекеттесу саны",
  beenFinished: "Аяқталды",
  notFinished: "Аяқталмаған",
  resetFilters: "Сүзгілерді қалпына келтіру",
  columns: "Спикерлер",
  deleteConfirmation: "Жолды жойғыңыз келетініне сенімдісіз бе ？",
  selectColumns: "Бағандарды таңдаңыз",
  columnsNotFound: "Бағандар табылмады",
  atLeastOneColumnsMustBeSelected: "Кемінде бір баған таңдалуы керек",
  deleteSelectedElementsConfirmation: "Барлық таңдалған элементтерді жойғыңыз келетініне сенімдісіз бе ？",
  report: "Есеп беру",
  questionsInSurvey: "Сауалнамадағы сұрақтар",
  answersOption: "Жауап опциясы",
  percentOfAnswers: "% жауаптар",
  display: "Көрсету",
  error: "Қате",
  resendCodeAgain: "Кодты қайта жіберіңіз",
  resendCode: "Кодты жіберу",
  deletedSuccessfully: "Сәтті жойылды",
  answered: "жауап берді",
  filesUploaded: "Жүктеп салынған файлдар",
  unavailable: "Қолжетімсіз",
  formatTime: {
    min: "мин",
    hrs: "час",
    sec: "сек",
    day: "күні",
    day2form: "күн",
    days: "күн",
    hour: "сағат",
    hour2form: "сағат",
    hours: "сағат",
    minute: "минут",
    minute2form: "минут",
    minutes: "минут",
    second: "секунд",
    second2form: "секунд",
    seconds: "секунд",
  },
  accountSettings: "Есеп жазбасын түзету",
  myAccount: "Менің аккаунтым",
  currentTariff: "Ағымдағы тарифтік жоспар",
  timezone: "Сағаттық белдеу",
  currentTimezone: "Ағымдағы уақыт белдеуі",
  tariffPlans: "Тарифтік жоспарлар",
  tariffPlan: "Тарифтік жоспар",
  selectTimezone: "Уақыт белдеуіңізді таңдаңыз",
  notChosen: "Таңдалмаған",
  selectThePlanThatSuitsYouBest: "Сізге ең қолайлы жоспарды таңдаңыз",
  choose: "Таңдау",
  currentPlan: "Ағымдағы жоспар",
  selectPaymentMethod: "Төлем әдісін таңдаңыз",
  paymentPage: "Төлем беті",
  moreAboutTariffs: "Тарифтер туралы толығырақ",
  role: "Рөл",
  addUser: "Пайдаланушы қосу",
  subUserPageHelpTxt:
    "Қосылған пайдаланушылар тағайындалған рөлге сәйкес бір-бірінің сауалнамаларын, соның ішінде сіздің сауалнамаларын көруге рұқсат алады.",
  userDeleteConfirmationText: "Пайдаланушыны жойғыңыз келетініне сенімдісіз бе ？",
  roles: "Рөлдері",
  users: "Пайдаланушылар",
  share: "Бөлісу",
  shareSurveyResults: "Сауалнама нәтижелерімен бөлісіңіз",
  accessOptions: "Қатынас параметрі",
  onlyForRegisteredUsers: "Тек тіркелген пайдаланушылар үшін",
  allWhoHasLink: "Сілтемесі бар кез келген адам",
  allWhoHasLinkAndPassword: "Сілтеме мен құпия сөзі бар барлық адамдар",
  sendLink: "Сілтеме жіберу",
  linkSent: "Сілтеме жіберілді",
  enterEmail: "e-mail енгізіңіз",
  userNotFound: "Пайдаланушы табылмады",
  aiAssistant: "AI assistant",
  extraOptions: "Қосымша опциялар",
  smsProviderDeleted: "SMS провайдері жойылды",
  smsProviderCreated: "SMS провайдері құрылды",
  smsProviderChanged: "SMS провайдері өзгерді",
  invalidUrl: "Жарамсыз URL",
  selectAll: "Барлығын таңдаңыз",
  questionsFilter: "Сұрақ сүзгісі",
  selectQuestions: "Сұрақтарды таңдаңыз",
  createAndContinue: "Жасау және Жалғастыру",
  downloadQr: "QR жүктеп алыңыз",
  showQr: "QR көрсету",
  copyQr: "QR көшіру",
  createDistribution: "Ақпараттық бюллетень жасаңыз",
  createQrCode: "QR кодын жасаңыз",
  questionsQuantity: "Сұрақтар саны",
  standard: "Стандартты",
  rounded: "Дөңгеленген",
  dots: "Ұпайлар",
  selectColor: "Түсті таңдаңыз",
  style: "Стиль",
  deleteLogo: "Логотипті жою",
  youHaveUnfilledFields: "Сізде толтырылмаған міндетті өрістер бар.",
  message: "Хабар",
  thankYouPage: "Рахмет парақшасы",
  welcomePage: "Сәлемдесу беті",
  footer: {
    termsOfUse: "Қолдану ережелері",
    privacyPolicy: "Құпиялылық саясаты",
    reference: "Анықтама",
  },
  distributionChannelCreation: "Тарату арнасын құру",
  saveAndCopy: "Сақтау және көшіру",
  saveAndShare: "Сақтау және бөлісу",
  linkToSurvey: "Сауалнама сілтемесі",
  shareOn: "Бөлісіңіз ",
  emailDistribution: "Электрондық хаттарды жіберу",
  smsDistribution: "SMS жіберу",
  dateAndTime: "Күні мен уақыты",
  additional: "Қосымша",
  moreDetails: "Толығырақ",
  month: "ай",
  distributionChannelsList: "Тарату арналарының тізімі",
  channelName: "Тарату арналарының атауы",
  questionMediaBanner: {
    label: "Медиа баннер",
    title: "Жүктеп салу үшін файлды осы аймаққа басыңыз немесе сүйреңіз",
    description: "Қолдау көрсетілетін файлдар: JPG, JPEG, PNG, GIF, MP4.",
    deleteConfirmationText: "Медиа баннерді шынымен жойғыңыз келе ме?",
    uploadedSuccessfullyText: "Медиа файл жүктеп салынды.",
  },
  color: "Түс",
  fileFormat: "Файл пішімі",
  buy: "Сатып алу",
  redirectUrl: "Қайта бағыттау url",
  identifierErrorMsg: "Идентификатор тек латын әріптерін, сандарды, сызықшаларды және астын сызуды қамтуы мүмкін.",
  socialMedia: "Әлеуметтік желілер",
  redirectToAnotherSite: "Басқа сайтқа қайта бағыттау",
  previewOfTheSurvey: "Сауалнаманы алдын ала қарау",
  firstname: "Аты",
  lastname: "Тегі",
  youHaveReachedMaxSurveys: "Сіз жазылымыңызға арналған қолжетімді сауалнамалардың ең көп санына жеттіңіз.",
  youHaveReachedMaxContacts: "Контакт шегінен асып кетті",
  showProgressBar: "Орындалу жолағын көрсету",
  templateTooltips: {
    SINGLE_CHOICE: "Ұсынылған нұсқалардан сұраққа жалғыз жауап алу үшін",
    MULTIPLE_CHOICE: "Сұраққа жауап берудің бірнеше нұсқасын таңдауға мүмкіндік береді",
    CSI: "Респондентке ұсынылған критерийлер бойынша әртүрлі параметрлерді бағалауға мүмкіндік береді. Бұл деректер тұтынушылардың қанағаттану индексін есептеуге көмектеседі.",
    NPS: 'Сұрағыңызға белгішелер ("жұлдыз", "эмодзилер", "жүректер", сандық қатар"), жүгірткі, ашылмалы тізім түрінде баға беруді ұсыныңыз. 5 немесе 10 балдық шкала бойынша бағалауға мүмкіндік береді.',
    FILE_UPLOAD:
      "Респондентке жауапқа файлдарды тіркеуге мүмкіндік беріңіз (түйіндеме, портфолио, фотосуреттер және басқа суреттер)",
    INFO_BLOCK: "Респондентке ерікті хабарлама көрсетіңіз . Жауап қажет емес.",
    START_BLOCK:
      "Бұл сауалнаманың бастапқы беті. Респондентпен сәлемдесіп, сауалнамаңыз туралы сөйлесіңіз. Жауап қажет емес.",
    END_BLOCK:
      "Сауалнаманың соңында респондентке хабарламаны көрсететін сауалнаманың соңғы беті. Жауап қажет емес. Веб-сайтыңызға қайта бағыттауға болады.",
    EMAIL: "Енгізілген электрондық пошта мекенжайының пішімін тексеру мүмкіндігі бар үлгі",
    IIN: "ЖСН сұрау үшін үлгі",
    NUMBER: "Респонденттің телефон нөмірін сұраңыз",
    TEXT: "Ашық сұрақтарға жауап жинау үшін.",
    DATE: "Респонденттен қажетті күнді сұраңыз (қызмет көрсетудің өткен немесе болашақ күнін көрсету үшін)",
    DATETIME:
      "Респонденттен қажетті күн мен нақты уақытты сұраңыз (қызмет көрсетудің өткен немесе болашақ күні мен уақытын көрсету үшін)",
  },
  propertyKey: "Мәтін үлгілерінің айнымалы атауы",
  theVariableIsRequiredForTexts:
    "Айнымалы мән пайдаланушыға қатынасу кезінде мәтіндерде пайдалану үшін қажет. Мысалы, сіз сауалнамада барлық респонденттерге аты бойынша хабарласатын хабар үлгісін пайдалана аласыз.",
  patternErrorText: 'URL сілтемесі "http://" немесе "https://" деп басталуы керек.',
  dataTypeText: "Мәтін",
  integerDataType: "Бүтін сан",
  numberWithFractionalPart: "Бөлшек бөлігі бар сан",
  stringList: "Тізім («,» бөлгіші)",
  dataTypeDate: "Күн",
  emailDataType: "Электрондық пошта",
  mobileNumber: "Мобильдік телефон номері",
  emailError: "Дұрыс электрондық поштаны енгізіңіз",
  phoneError: "Дұрыс телефон нөмірін енгізіңіз",
  basedOnPreviousResponses: "Алдыңғы жауаптар негізінде",
  basedOnContactData: "Контакт деректері негізінде",
  highestScoreLabel: "Жоғары бағалауға арналған мәтін",
  lowestScoreLabel: "Төменгі бағаға арналған мәтін",
  npsTrademark:
    "NPS®, Net Promoter және Net Promoter Score — Satmetrix Systems, Inc., Bain & Company және Fred Reichheld тіркелген тауар таңбалары",
  attentionAnswerNotRequired: "Назар аударыңыз, сілтеме жасалған сұрақтың жауабы міндетті емес.",
  distributionStartConfirmationTooltip: "Ақпараттық бюллетеньді бастау",
  distributionPauseConfirmationTooltip: "Хабарламаны кідірту",
  distributionFinishConfirmationTooltip: "Пошта жіберуді тоқтату",
  fixedNumberOfSelectedOptions: "Таңдалған параметрлердің тіркелген саны",
  ranging: "Ранжирование",
  mixOptions: "Аралас опциялары",
  hideLineNumbers: "Жол нөмірлерін жасыру",
  noAnswersAdded: "Жауаптар әлі қосылмаған",
  subscriptionSurvey: "Survey Suite жазылымы",
  carefully: "Абайлаңыз!",
  absoluteValues: "Абсолютті мәндерді көрсетіңіз",
  percentage: "Пайыз",
  limitedRanging: "Шектеулі рейтинг",
  numberOfAvailableAssessments: "Қолжетімді рейтингтер саны",
  fine: "Айыппұл",
  badly: "Нашар",
  averageRating: "Орташа рейтинг",
  loyaltyIndexClient: "Адалдық индексі",
  integerError: "Тұтас санды енгізіңіз",
  decimalError: "Бөлшек санды енгізіңіз",
};
